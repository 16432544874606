@property --myColor1 {
  syntax: "<color>";
  initial-value: #45bdf9;
  inherits: false;
}

@property --myColor2 {
  syntax: "<color>";
  initial-value: #d84375;
  inherits: false;
}

.cta-template-container {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 687px) {
    flex-direction: column;
  }
}

.call-to-action {
  @extend %panel--black;
  text-align: center;
  margin-top: $section-spacing;

  main &:last-child {
    // margin-bottom: -$section-spacing;
  }

  .article + &,
  .blog-post + & {
    margin-bottom: 0;
  }

  > .container {
    @extend %clearfix;

    > p {
      @media (min-width: $l) {
        @include layout($wide);
        width: span(8 of 12);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .feature + &,
  .testimonial + &,
  .more-posts + &,
  .profiles + & {
    margin-top: 0;
  }

  .button {
    @extend %button;
    margin-top: 1em;
    border: 2px solid #40afe8;
  }

  &.ai-call-to-action {
    padding: 6em 4em;
    background: #2f3b49;
    color: #fff;
    text-align: center;
    // margin-top: 3.5em;

    .ai-cta-button {
      @extend %button;
      margin-top: 1em;
      border-radius: 50px;
      border: 0;
      font-size: 20px;
      background-image: linear-gradient(to bottom right, #40afe8, #3a9dce);
    }

    .cta-ai-secondary-text {
      font-size: 20px;
    }

    .ai-cta-button:hover {
      background-image: linear-gradient(to top left, #40afe8, #3a9dce);
    }

    .gradient-robot {
      font-size: 75px;
      padding-bottom: 25px;
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      background-image: linear-gradient(
        45deg,
        var(--myColor1),
        var(--myColor2)
      );
      transition: --myColor1 0.5s, --myColor2 0.5s;

      &:hover {
        --myColor1: #d84375;
        --myColor2: #45bdf9;
      }
    }

    @media only screen and (max-width: 600px) {
      padding: 2em;
      .cta-ai-secondary-text {
        font-size: 18px;
      }

      .cta-ai-header-text {
        font-size: 24px;
      }

      .gradient-robot {
        font-size: 60px;
      }
    }
  }

  .button-secondary {
    @extend %button;
    color: $colour--primary--dark !important;
    border-color: $colour--primary--dark;
    background: $white;

    :hover {
      background: $colour--primary--dark;
      color: $white;
    }
  }
}

.cta-homepage {
  margin-top: 0 !important;
}

.cta-single {
  .text {
    @media (min-width: $m) {
      @include layout($medium);
      @include span(last 4 of 6);
      text-align: left;
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(last 9 of 12);
      // margin-right: span(2 of 12);
    }
  }

  [class^="illustration--"] {
    max-width: 100%;
    height: auto;
    margin-top: 1.5em;

    @include layout($narrow);
    @include pre(1 of 4);
    @include span(2 of 4);

    @media (min-width: $s) {
      @include layout($narrow);
      @include pre(1 of 4);
      @include span(2 of 4);
    }

    @media (min-width: $m) {
      @include layout($medium);
      @include pre(0);
      @include span(2 of 6);
      clear: none;
      max-width: none;
      margin-top: 0;
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include pre(0);
      @include span(3 of 12);
      max-width: none;
      margin-top: 0;
    }
  }
}

.cta-group {
  background-color: transparent;
  padding: 0;

  [class^="illustration--"] {
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $xs) {
      max-width: 50%;
    }

    @media (min-width: $m) {
      max-width: 40%;
    }

    @media (min-width: $l) {
      max-width: 70%;
    }
  }

  [class^="lead-"] {
    @extend %panel;
    padding-top: $section-spacing / 2;
    padding-bottom: $section-spacing / 2;
    color: $colour--black;
    background-color: #f4f4f4;
    border-radius: $border-radius;

    & + [class^="lead-"] {
      margin-top: $section-spacing / 2;
    }
  }

  .lead-ice {
    background-color: $colour--grey--darkest;
    color: $colour--grey--lightest;
  }

  .lead-warm {
    background-color: $colour--grey--lighter;
  }

  .lead-hot {
    background-color: $colour--grey;
    background-color: $colour--black;
    color: $colour--white;
  }
}

.gradient-checks {
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-image: linear-gradient(45deg, var(--myColor1), var(--myColor2));
  transition: --myColor1 0.5s, --myColor2 0.5s;

  &:hover {
    --myColor1: #d84375;
    --myColor2: #45bdf9;
  }
}
