.pricing {
  @extend %clearfix;
  @extend %panel;
  border: 1px solid rgb(224, 224, 224);
  background-color: $colour--grey--lightest;
  margin-top: $section-spacing;
  padding-top: 2.5rem;
  overflow: hidden;
  //   border-radius: 20px;
  box-shadow: 3px 2px 3px 2px rgb(224, 224, 224);
}

.pricing-wrapper {
  overflow: hidden;
  // @include layout($medium);
  @include span(12 of 12);
  margin-bottom: $section-spacing/3;

  .row {
    @include span(12 of 12);
    padding-top: $section-spacing/6;
    display: block;

    @media (min-width: $m) {
      @include layout($medium);
      display: flex;
      align-items: center;
    }

    &:first-of-type() {
      padding-top: 0;
    }
  }

  .pricing-header {
    @include span(12 of 12);
    text-align: center;

    .header-items {
      @include span(12 of 12);

      @media (min-width: $m) {
        @include layout($medium);
        @include pre(first 4 of 12);
        @include span(last 8 of 12);
      }

      .header-item {
        @include layout($medium);
        @include span(2 of 6);

        &:nth-of-type(3n) {
          @include layout($medium);
          @include span(last 2 of 6);
        }

        h3 {
          margin-top: 0;
        }
      }
    }
  }

  .pricing-body {
    @include span(12 of 12);
    margin-top: 10px;

    .row {
      padding-top: 0;
    }

    .pricing-detail {
      @include layout($narrow);
      @include span(12 of 12);
      background-color: $colour--grey--darkerer;
      color: $colour--white;
      text-align: center;
      padding: 10px;

      @media (min-width: $m) {
        @include layout($medium);
        @include span(first 4 of 12);
        background-color: transparent;
        color: $colour--black;
        text-align: left;
      }
    }

    .body-items {
      position: relative;
      text-align: center;

      @include layout($narrow);
      @include span(12 of 12);
      // clear: both;

      @media (min-width: $m) {
        @include layout($medium);
        @include span(last 8 of 12);
      }

      .body-item {
        @include layout($medium);
        @include span(2 of 6);
        padding: 10px 0;
        line-height: 1;

        &:nth-of-type(3n) {
          @include layout($medium);
          @include span(last 2 of 6);
        }

        span {
          svg {
            margin: 0 auto;
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}

.pricing-tiers {
  @extend %clearfix;
  @extend %panel;
  background-color: $colour--grey--lightest;
  margin-top: $section-spacing;
  padding-top: $section-spacing;

  @media (min-width: $l) {
    padding-top: $section-spacing * 1.75;
  }

  .container {
    > ul {
      @extend %list-style-remove;
    }
  }

  .pricing-tier-item {
    margin-bottom: $section-spacing/2;

    @media (min-width: $m) {
      @include layout($medium);
      @include span(2 of 6);
      margin-bottom: 0;

      &:nth-of-type(3n) {
        @include layout($medium);
        @include span(last 2 of 6);
      }

      &:nth-of-type(4n) {
        clear: both;
      }
    }

    @media (min-width: $l) {
      @include layout($wide);
      @include span(4 of 12);

      &:nth-of-type(3n) {
        @include layout($wide);
        @include span(last 4 of 12);
      }

      &:nth-of-type(4n) {
        clear: none;
      }
    }
  }

  .most-popular {
    @media (min-width: $l) {
      margin-top: -3em;
    }
  }
}

.toggle {
  display: flex;
  //   margin-bottom: 36px;
  overflow: hidden;
  justify-content: center;
}

.toggle .toggle-parent {
  border: 1px solid #3b384042;
  border-radius: 50px;
  padding: 3px;
  display: flex;
  align-items: center;
}

.toggle input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.toggle label {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  //   box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.toggle label:hover {
  cursor: pointer;
}

.toggle input:checked + label {
  background-color: #4b174d;
  box-shadow: none;
  color: rgb(255, 255, 255);
}

.toggle input:checked > label {
  margin: 10px;
}

.toggle label {
  border-radius: 50px;
}

.what-we-offer-div {
  width: 48%;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  background-color: $colour--grey--lightest;
  margin: 5px;
  box-shadow: 3px 2px 3px 2px #e0e0e0;
  border-radius: 10px;

  @media screen and (max-width: 1128px) {
    width: 100% !important;
    margin: 10px;
  }
}

.benefits-list {
  text-align: left;
}

// .pricing.container {
//   @media screen and (max-width: 1120px) {
//     margin: 10px;
//   }
// }

.offer-header {
  font-size: 1.4em;
  font-weight: 500;
  padding-bottom: 10px;
}

.offer-link {
  display: flex;
  justify-content: center;
  a {
    color: #40afe8;
    font-size: 0.9em;
    padding-top: 10px;
    text-decoration: none;
    font-size: 1em;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
    }
  }
}

.offer-feature {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  font-size: 22px;
  font-weight: 300;
  color: #3b3840;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;

  span {
    padding-top: 5px;
    padding-right: 5px;
    svg {
      align-items: flex-start;
      margin: 0 auto;
      min-width: 1em;
      min-height: 1em;
      height: 1em;
      width: 1em;
    }
  }
}

.staffology-feature {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  font-size: 0.9em;
  font-weight: 300;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;

  span {
    padding-top: 5px;
    padding-right: 5px;
    svg {
      align-items: flex-start;
      margin: 0 auto;
      min-width: 1em;
      min-height: 1em;
      height: 1em;
      width: 1em;
    }
  }
}

.pricing-question {
  font-size: 1.5rem;
  font-weight: 300;
  color: #3b3840 !important;
}

.slider-container {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.slider {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  background: rgb(255, 255, 255);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 9999px;
  background: #4b174d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 9999px;
  background: #4b174d;
  cursor: pointer;
}

#costOutput {
  font-size: 1.3em;
  color: #3b3840;
  font-weight: 300;
  padding-top: 25px;
  padding-right: 15px;
  padding-left: 15px;
}

.gradient-pricing-text {
  font-weight: 500;
  background: -webkit-linear-gradient(45deg, #c03c67, #4b174d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation: textShine 5s ease-in-out infinite alternate;
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.modal-container {
  width: 160px;
  .modal-btn {
    display: block;
    margin: 0 auto;
    color: #fff;
    width: 160px;
    height: 50px;
    line-height: 50px;
    background: #4b174d;
    font-size: 22px;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 5px 5px -5px #333;
    transition: background 0.3s ease-in;
    &:hover {
      background: #3a123b;
    }
  }

  .modal-content,
  .modal-btn2 {
    border-radius: 5px;
  }
  .modal-content,
  .modal-backdrop {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    cursor: pointer;
    transition: opacity 0.2s ease-in;
  }
  .modal-close {
    color: #aaa;
    position: absolute;
    right: 5px;
    top: 5px;
    padding-top: 3px;
    background: #fff;
    font-size: 16px;
    width: 25px;
    height: 25px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }

  .modal-btns-div {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    width: 90%;
    padding-bottom: 20px;
    .modal-content-btn {
      text-align: center;
      cursor: pointer;
      bottom: 20px;
      right: 30px;
      background: #4b174d;
      color: #fff;
      width: auto;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 10px 20px;
      &:hover {
        color: #fff;
        background: #3a123b;
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .modal-content-btn-close {
      text-align: center;
      cursor: pointer;
      bottom: 20px;
      left: 30px;
      background-color: #fff;
      color: #4b174d;
      border: 1px solid #4b174d;
      width: auto;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 10px 20px;
      &:hover {
        background-color: rgb(244, 243, 243);
        color: #3a123b;
      }
    }
  }

  #modal-toggle {
    display: none;
    &.active ~ .modal-backdrop,
    &:checked ~ .modal-backdrop {
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
    &.active ~ .modal-content,
    &:checked ~ .modal-content {
      opacity: 1;
      background-color: #fff;
      max-width: 600px;
      width: 600px;
      height: 260px;
      padding: 10px 30px;
      position: fixed;
      left: calc(50% - 300px);
      top: 25%;
      z-index: 999;
      pointer-events: auto;
      cursor: auto;
      visibility: visible;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6);
      @media (max-width: 650px) {
        left: 0;
        width: auto;
        max-width: auto;
      }
    }
  }
}

.panel {
  position: relative;
  width: 100%;
}

.pricing-table-div {
  padding: 60px 0 40px 0;
}

.pricing-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  box-shadow: 3px 2px 3px 2px #e0e0e0;

  th,
  td {
    border: 1px solid #ddd;
    padding: 10px;
  }
  th {
    background-color: #ffffff;
    text-align: left;
    color: #333;
  }

  td:first-child,
  th:first-child {
    max-width: 40%;
    width: 40%;
    word-wrap: break-word;
    font-size: 15px;
  }

  /* For the other columns */
  td:nth-child(n + 2),
  th:nth-child(n + 2) {
    width: 15%;
  }

  .check {
    color: #4dbff8;
    font-weight: bold;
  }
  .cross {
    color: gray;
    font-weight: bold;
  }
  .brand {
    text-align: left;
    font-weight: bold;
  }
}

@media (min-width: 1000px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-plan {
  border: 1px solid #3b384042;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 3px 2px 3px 2px #e0e0e0;

  text-align: left;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-plan.primary-border {
  border: 3px solid #591b5a !important;
}

@media (min-width: 1000px) {
  .pricing-plan {
    flex-basis: 100%;
    padding: 10px 20px;
  }
}

.pricing-img {
  margin-bottom: 25px;
  max-width: 100%;
}

.pricing-header {
  color: #3a123b;
  font-weight: 500;
  //   padding-left: 20px;
  text-align: center;
}

.contracts-header {
  color: #3b3840;
  font-weight: 500;
  font-size: 1.3em;
}

.contracts-sub-header {
  display: block;
  color: #3b3840;
  font-weight: 500;
}

.pricing-length {
  color: #3b3840;
  font-size: 15px;
  //   padding-left: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.pricing-secondary-header {
  color: #3b3840;
  font-size: 15px;
  padding-left: 20px;
  font-weight: 400;

  span {
    color: #3a123b;
    font-weight: 600;
  }
}

.pricing-features {
  color: #3b3840;
  font-weight: 300;
  margin: 25px 0px;
}

.pricing-features-item {
  border-top: 1px solid #3b384042;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #3b384042;
}

.demo-button {
  display: flex;
  justify-content: center;

  .pricing-button {
    border: 1px solid #43b6ef;
    border-radius: 5px;
    background-color: #40afe8;
    color: #fff;
    display: inline-block;
    margin: 25px 0;
    padding: 0.5em 1.5em;
    text-decoration: none;
    transition: all 150ms ease-in-out;
  }

  .pricing-button:hover,
  .pricing-button:focus {
    background-color: #4dbff8;
    border-radius: 5px;
  }
}

.most-popular-tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #8a458a;
  border-radius: 25px;
  margin: 6px;
  color: white;
  font-size: 12px;
}
